.new-log {
  display: flex;
  flex-flow: column;

  header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    h1 {
      font-size: 2rem;
      margin: 1rem auto;
      padding: 1rem;
      text-align: center;
      border: #fff 5px solid;
    }
  }

  main {
    display: flex;
    justify-content: center;
    height: auto;

    form {
      width: 80vw;
      border: 5px solid #0074d9;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      padding: 5px;

      label {
        color: #7fdbff;
        display: flex;
        flex-flow: column;

        input,
        textarea {
          margin: 1rem auto;
          border-radius: 0;
          border: 2px solid #0074d9;
          background: #aaaaaa;
          font-size: 1rem;
          width: 80%;
        }

        textarea {
          height: 5rem;
        }
      }

      input[type="submit"] {
        cursor: pointer;
        background: #000;
        border: 4px solid #0074d9;
        color: #7fdbff;
        font-size: 1rem;

        &:hover {
          border: 4px solid #7fdbff;
        }
      }
    }
  }

  footer {
    display: flex;
    justify-content: center;

    button {
      font-size: 2rem;
      border: 5px solid #0074d9;
      color: #0074d9;
      background: #000;
      padding: 0.5rem 1rem;
      cursor: pointer;

      &:hover {
        color: #7fdbff;
        border: #7fdbff 5px solid;
      }
    }
  }

  .new-log_wrapper {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    height: auto;

    main {
      margin: 1rem auto;
    }
  }
}
