@import "../../colors.scss";

.login-form {
  min-width: 60vw;
  border: 5px solid $log-color;
  padding: 1rem;

  h2 {
    color: $log-color;
  }

  form {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 10rem;

    label {
      display: flex;
      flex-flow: column;

      input {
        color: $primary-color-text;
        background: $background-color;
        border: 2px solid $log-color;
        padding: 0.35rem 0.25rem;
      }
    }

    input[type="submit"] {
      border: 2px solid $log-color;
      background: $background-color;
      color: $primary-color-text;
      padding: 0.35rem 0.25rem;
      font-family: "Fira Code", monospace;
    }
  }
}
