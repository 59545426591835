@import "../../colors.scss";

.log-detail {
  main {
    display: flex;
    flex-flow: column;
    justify-content: space-around;

    & > div:last-child {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      & > * {
        border: 1px solid $detail-color;
      }
    }
  }

  footer {
    display: flex;
    justify-content: center;

    button {
      font-size: 2rem;
      border: 5px solid $detail-color;
      color: $detail-color;
      background: $background-color;
      padding: 0.5rem 1rem;
      cursor: pointer;
      margin: 1rem auto;

      &:hover {
        color: $detail-color-active;
        border: $detail-color-active 5px solid;
      }
    }
  }
}
