@import url(https://fonts.googleapis.com/css?family=Fira+Code&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background: #000;
  color: #fff;
  font-family: "Fira Code", monospace; }

.app {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 100vh; }
  .app header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center; }
    .app header h1 {
      font-size: 2rem;
      margin: 1rem auto;
      padding: 1rem;
      text-align: center;
      border: #fff 5px solid; }
    .app header button {
      border: #b10dc9 5px solid;
      color: #b10dc9;
      background: none;
      font-size: 2rem;
      cursor: pointer; }
      .app header button:hover {
        background: rgba(60, 60, 60, 0.5); }
  .app main {
    flex: 3 1;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around; }
  .app footer {
    flex: 1 1;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    max-height: 3.5rem; }
    .app footer button {
      background: none;
      font-size: 2rem;
      cursor: pointer;
      background: #000; }
    .app footer .sign-out {
      flex: 1 1;
      border: 5px solid #b10dc9;
      color: #b10dc9; }
      .app footer .sign-out:hover {
        border: 5px solid #f012be;
        color: #f012be; }
    .app footer .add-log {
      flex: 3 1;
      text-decoration: none;
      font-size: 2rem;
      text-align: center;
      border: 5px solid #2ecc40;
      color: #2ecc40;
      display: flex;
      justify-content: center;
      align-items: center; }
      .app footer .add-log:hover {
        border: 5px solid #01ff70;
        color: #01ff70; }

.login {
  border: #39cccc 5px solid; }
  .login h2 {
    color: #39cccc; }

.newUser {
  border: #b10dc9 5px solid; }
  .newUser h2 {
    color: #b10dc9; }

.loader {
  display: flex;
  justify-content: center;
  align-content: flex-start; }

img {
  height: 30vh; }

.search {
  margin: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center; }
  .search form {
    margin: auto;
    display: flex;
    flex-flow: row nowrap;
    border: 3px solid #39cccc; }
    .search form * {
      border: none;
      color: #fff;
      background: #000; }
      .search form * img {
        height: auto;
        height: 20px; }
    .search form button {
      padding: 0.5rem; }
    .search form input {
      padding: 0 0.5rem;
      font-size: 1rem; }

main {
  overflow: auto;
  height: auto; }

.log {
  border: yellow 5px solid;
  padding: 1rem 2rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  margin: 3rem auto auto auto;
  max-width: 20rem;
  position: relative; }
  .log:hover {
    border: #ffe500 5px solid; }
  .log h2 {
    color: yellow;
    margin: 1rem 0;
    font-size: 1.3rem; }
    .log h2::first-letter {
      text-transform: capitalize; }
    .log h2:hover {
      color: #ffe500; }
  .log .log_remove {
    position: absolute;
    right: 1rem;
    top: 1rem;
    background: none;
    border: 1px solid #ff4136;
    color: #ff4136;
    cursor: pointer; }
    .log .log_remove:hover {
      background: rgba(255, 65, 54, 0.3); }
  .log .log_date {
    margin-bottom: 0.5rem;
    color: #aaa; }
  .log a {
    text-decoration: none; }

.login-form {
  min-width: 60vw;
  border: 5px solid yellow;
  padding: 1rem; }
  .login-form h2 {
    color: yellow; }
  .login-form form {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 10rem; }
    .login-form form label {
      display: flex;
      flex-flow: column; }
      .login-form form label input {
        color: #fff;
        background: #000;
        border: 2px solid yellow;
        padding: 0.35rem 0.25rem; }
    .login-form form input[type="submit"] {
      border: 2px solid yellow;
      background: #000;
      color: #fff;
      padding: 0.35rem 0.25rem;
      font-family: "Fira Code", monospace; }

.newUser-form {
  min-width: 60vw;
  border: 5px solid #b10dc9;
  padding: 1rem;
  margin: 1rem; }
  .newUser-form h2 {
    color: #b10dc9; }
  .newUser-form form {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 10rem; }
    .newUser-form form label {
      display: flex;
      flex-flow: column; }
      .newUser-form form label input {
        color: #fff;
        background: #000;
        border: 2px solid #b10dc9;
        padding: 0.35rem 0.25rem; }
    .newUser-form form input[type="submit"] {
      border: 2px solid #b10dc9;
      background: #000;
      color: #fff;
      padding: 0.35rem 0.25rem;
      font-family: "Fira Code", monospace; }

.new-log {
  display: flex;
  flex-flow: column; }
  .new-log header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center; }
    .new-log header h1 {
      font-size: 2rem;
      margin: 1rem auto;
      padding: 1rem;
      text-align: center;
      border: #fff 5px solid; }
  .new-log main {
    display: flex;
    justify-content: center;
    height: auto; }
    .new-log main form {
      width: 80vw;
      border: 5px solid #0074d9;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      padding: 5px; }
      .new-log main form label {
        color: #7fdbff;
        display: flex;
        flex-flow: column; }
        .new-log main form label input,
        .new-log main form label textarea {
          margin: 1rem auto;
          border-radius: 0;
          border: 2px solid #0074d9;
          background: #aaaaaa;
          font-size: 1rem;
          width: 80%; }
        .new-log main form label textarea {
          height: 5rem; }
      .new-log main form input[type="submit"] {
        cursor: pointer;
        background: #000;
        border: 4px solid #0074d9;
        color: #7fdbff;
        font-size: 1rem; }
        .new-log main form input[type="submit"]:hover {
          border: 4px solid #7fdbff; }
  .new-log footer {
    display: flex;
    justify-content: center; }
    .new-log footer button {
      font-size: 2rem;
      border: 5px solid #0074d9;
      color: #0074d9;
      background: #000;
      padding: 0.5rem 1rem;
      cursor: pointer; }
      .new-log footer button:hover {
        color: #7fdbff;
        border: #7fdbff 5px solid; }
  .new-log .new-log_wrapper {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    height: auto; }
    .new-log .new-log_wrapper main {
      margin: 1rem auto; }

.code-textarea * {
  margin: auto; }

.log-detail main {
  display: flex;
  flex-flow: column;
  justify-content: space-around; }
  .log-detail main > div:last-child {
    display: grid;
    grid-template-columns: repeat(2, 1fr); }
    .log-detail main > div:last-child > * {
      border: 1px solid #00db07; }

.log-detail footer {
  display: flex;
  justify-content: center; }
  .log-detail footer button {
    font-size: 2rem;
    border: 5px solid #00db07;
    color: #00db07;
    background: #000;
    padding: 0.5rem 1rem;
    cursor: pointer;
    margin: 1rem auto; }
    .log-detail footer button:hover {
      color: #b9f5bb;
      border: #b9f5bb 5px solid; }

