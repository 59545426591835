@import url("https://fonts.googleapis.com/css?family=Fira+Code&display=swap");
@import "./colors.scss";

body {
  background: $background-color;
  color: $primary-color-text;
  font-family: "Fira Code", monospace;
}

.app {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 100vh;

  header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    h1 {
      font-size: 2rem;
      margin: 1rem auto;
      padding: 1rem;
      text-align: center;
      border: $primary-color-border 5px solid;
    }

    button {
      border: $action-color 5px solid;
      color: $action-color-text;
      background: none;
      font-size: 2rem;
      cursor: pointer;

      &:hover {
        background: rgba(60, 60, 60, 0.5);
      }
    }
  }

  main {
    flex: 3;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
  }

  footer {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    max-height: 3.5rem;

    button {
      background: none;
      font-size: 2rem;
      cursor: pointer;
      background: #000;
    }

    .sign-out {
      flex: 1;
      border: 5px solid #b10dc9;
      color: #b10dc9;

      &:hover {
        border: 5px solid #f012be;
        color: #f012be;
      }
    }

    .add-log {
      flex: 3;
      text-decoration: none;
      font-size: 2rem;
      text-align: center;
      border: 5px solid #2ecc40;
      color: #2ecc40;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        border: 5px solid #01ff70;
        color: #01ff70;
      }
    }
  }
}

.login {
  border: #39cccc 5px solid;

  h2 {
    color: #39cccc;
  }
}

.newUser {
  border: #b10dc9 5px solid;

  h2 {
    color: #b10dc9;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-content: flex-start;
}

img {
  height: 30vh;
}
