@import "../../colors.scss";

.search {
  margin: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  form {
    margin: auto;
    display: flex;
    flex-flow: row nowrap;
    border: 3px solid #39cccc;

    * {
      border: none;
      color: #fff;
      background: #000;

      img {
        height: auto;
        height: 20px;
      }
    }

    button {
      padding: 0.5rem;
    }

    input {
      padding: 0 0.5rem;
      font-size: 1rem;
    }
  }
}

main {
  overflow: auto;
  height: auto;
}
