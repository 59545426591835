$background-color: #000;
$primary-color-text: #fff;
$primary-color-border: #fff;

$action-color: #b10dc9;
$action-color-text: $action-color;

$log-color: yellow;
$log-color-active: #ffe500;
$log-color-text: $log-color;

$detail-color: #00db07;
$detail-color-active: #b9f5bb;
